.Layout {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.Layout main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 1024px;
    margin: 10px auto 30px auto;
}

.Layout main h1 {
    margin-top: 0;
    color:#25a0dc;
}

.header {
    background-color: #039be5;
}
.hcontent {
    height: 50px;
    width: 1024px;
    margin: 0 auto;
    display: flex;
    flex-flow: row;
}

.footer {
    display: flex;
    flex-flow: row;
    justify-content: center;
    padding: 20px 0;
    width: 100%;
    border-top: 1px solid #cfcfcf;
    background-color: #039be5;
    color:#fff;
}