.Forms {
    display: flex;
    flex-flow: row;
}
.Form {
    width: 360px;
}
.TabList {
    border-bottom: 1px solid #aaa;
    margin: 0;
    padding: 0;
}
.TabListPanelSelected {
    display: block !important;
    border-radius: 0px 0px 5px 5px;
    padding: 10px;
    border: #aaa 1px solid;
    border-top: none;
}