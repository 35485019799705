.Container {
    display: flex;
    flex-flow: column;
}
.FieldsList {
    flex-grow: 1;
}
.FieldsList th {
    text-align: right;
}
.Controls {
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
}
.Container h2 {
    margin-bottom: 0;
}

.Container i {
    margin: 10px 0;
}
.ConfirmDialog {

}
.ConfirmButtons {
    display: flex;
    flex-flow: row;
    justify-content: center;
    padding: 20px 0;
}