.Container {
    display: flex;
    flex-flow: column;
}
.listTable  td {
    padding: 3px;
}
.listTable tr:nth-child(odd) td {
    background-color: #d0e3ec;
}
.Controls {
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
}
.Container h2 {
    margin-bottom: 0;
}

.Container i {
    margin: 10px 0;
}
.blocked {
    text-decoration: line-through;
}
.itemControls {
    text-align: center;
}
.itemControls b {
    cursor: pointer;
    color:#038ed2;
    display: inline-block;
    margin-left: 10px;
}
.itemControls b:hover {
    color:#000000;
}
.addButton {
    cursor: pointer;
    color:#038ed2;
    display: inline-block;
    font-size: 13pt;
}
.addButton:hover {
    color:#000000;
}