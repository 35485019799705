.Container{
    display: flex;
    flex-flow: row;
}
.FieldsList {
    flex-grow: 1;
}
.FieldsList th {
    text-align: right;
}
.FieldsList th, .FieldsList td{
    width: 50%;
    padding: 0 10px;
}
.Controls{
    display: flex;
    flex-flow: column;
}
.Controls button {
    margin-bottom: 5px;
}
.disabled {
    color:#9c9c9c;
}
.deleteConfirmForm {
    display: flex;
    flex-flow: column;
}
.deleteConfirmFormControls {
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
}
.controllers td {
    background-color: #d5e6ef;
    font-size: 9pt;
    padding: 3px;
    min-width: 100px;
}
.controllers td:nth-child(1) {
    text-align: center;
}
.controllers td:nth-child(3),
.controllers td:nth-child(4) {
    text-align: right;
}
.err td {
    background-color: #f01f30;
    color: #fff;
}
.cLimit {
    cursor: pointer;
}
.controllers td input {
    width: 55px
}

.controllers td:nth-child(3):hover{
    background-color: #b9c9d2;
}
.controllers .err td:nth-child(3):hover{
    background-color: #b92935;
}
.logsTable {
    border: none;
}
.logsTable td {
    background-color: #d5e6ef;
    font-size: 9pt;
    padding: 3px;
}
.logsTable td button{
    font-size: 8px;
    padding: 0 5px;
    margin: 0;
    height: 20px;
    width: 100%;
}
.logsControls {
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
}
.logContent p {
    margin: 0;
    font-size: 11px;
}
.logContent img {
    margin: 0 auto;
    display: block;
}