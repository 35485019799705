.Auth {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    width: 100%;
}

.Auth > div {
    width: 100%;
    max-width: 400px;
}

.Auth h1 {
    color: #b1b1b1;
    text-align: center;
}

.AuthForm {
    background: #eee;
    /*box-shadow: 2px 2px 2px rgba(0, 0, 0, .5);*/
    padding: 15px;
    border-radius: 5px;
}
.AuthGeneralError {
    color: #f01f30;
    text-align: center;
}