.UserPanel {
    display: flex;
    flex-flow: row;
    justify-content: center;
    width: 200px;
    cursor: pointer;
    position: relative;
}
.UserPanel:hover {
    background-color: #038ed2;
}

.UserPanel .avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 5px;
}

.UserPanel .info {
    display: flex;
    color:#fff;
    margin-left: 10px;
    align-items: center;
    text-align: right;
    padding: 5px;
}
.userMenu {
    display: none;
    position: absolute;
    right: 0;
    top: 50px;
    list-style: none;
    background-color: white;
    padding: 0;
    margin: 0;
    width: 200px;
}

.UserPanel:hover .userMenu {
    display: block;
}

.userMenu li {
    display: block;
}
.userMenu li a {
    display: flex;
    text-decoration: none;
    color: #038ed2;
    height: 33px;
    padding-right: 5px;
    align-items: center;
    justify-content: flex-end;
}
.userMenu li a:hover {
    background-color: #038ed2;
    color:#fff;
}