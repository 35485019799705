.Plitka {
    display: flex;
    flex-flow: column;
    color:white;
    padding: 20px;
    text-align: center;
}
.title {
    font-size: 15pt;
    text-transform: uppercase;
}
.value {
    flex-grow: 1;
    font-size: 35pt;
    font-weight: bold;
}

.total {
    background-color: #038ed2;
}
.online {
    background-color: #46bd7b;
}
.disabled {
    background-color: #7d7d7d;
}
.danger {
    background-color: #f01f30;
}