.Button {
    display: flex;
    padding: 0 20px;
    border-radius: 4px;
    border: 1px solid #ccc;
    color: #000;
    margin-right: 15px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    flex-direction: row;
    align-items: center;
}

.Button:focus {
    outline: none;
}

.Button:active {
    box-shadow: inset 2px 2px 1px rgba(0, 0, 0, .3);
}

.Button:disabled {
    background: #ccc;
    color: #000;
    cursor: not-allowed;
}

.Button img {
    height: 25px;
    margin-right: 5px;
    margin-left: -15px;
}
.Button div {
    height: 25px;
    margin-top: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
}

.error {
    background: rgb(240, 179, 177);
}

.success {
    background: rgb(211, 239, 179);
}

.primary {
    background: #2884f6;
    color: #fff;
}
.processIcon {

}