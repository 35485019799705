.Navigation {
    display: flex;
    flex-grow: 1;
}
.Navigation ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}
.Navigation ul li {
    display: flex;
    margin: 0;
}
.Navigation ul li a {
    display: flex;
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    text-decoration: none;
    background-color: #039be5;
    transition: opacity .3s;
    padding: 0 20px;
    justify-content: center;
    align-items: center;
}
.Navigation ul li a:hover, .Navigation ul li a.active {
    background-color: #038ed2;
}