.Button {
    display: flex;
    padding: 0 20px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    flex-direction: row;
    align-items: center;
    background-color: #039be5;
    color: white;
    outline: none;
    border: none;
    font-weight: normal;
}
.Button:focus{
    border: none;
}
.Button.active {
    background-color: #007ab5;
}
.Button:disabled {
    background: #8b8681;
    color: #ffffff;
    cursor: not-allowed;
}
.Button:hover{
    background-color: #0090d6;
}
.Button img {
    height: 25px;
    margin-right: 5px;
    margin-left: -15px;
}
.Button div {
    height: 25px;
    margin-top: 2px;
    display: flex;
    align-items: center;
}