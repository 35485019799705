.Dashboard {
    padding-top: 5px;
    flex-grow: 1;
    width: 100%;
}
.DashGrid {
    display: flex;
    flex-flow: row;
    justify-content:space-between;
}

.DashGrid a {
    text-decoration: none;
}