.ObjectsList {
    padding-top: 5px;
    flex-grow: 1;
    width: 100%;
}

.ObjectsList table {
    width: 100%;
}

.ObjectsList table td {
    padding: 5px;
}
.ObjectsList table tr {
    cursor: pointer;
}
.ObjectsList table th {
    color: #038ed2;
}

.online {
    background-color: #46bd7b;
    color:#fff;
}
.online:hover {
    background-color: #41a76a;
}

.offline {
    background-color: #de5c4f;
    color:#fff;
}
.offline:hover {
    background-color: #bc584b;
}

.disabled {
    background-color: #b7b7b7;
    color:#fff;
}
.disabled:hover {
    background-color: #9e9e9e;
}

.controls{
    display: flex;
    flex-flow: row;
}
.filter {
    display: flex;
    flex-flow: row;
    flex-grow: 1;
}
.addButton {
    width: 40px;
    color: #039be5;
    text-decoration: none;
    text-align: center;
    font-size: 27px;
    line-height: 1px;
}
.addButton:hover{
    color: #007ab5;
}
.ObjectsList table td.objStatus {
    padding: 0;
    background-color: white;
    color: #f01f30;
    font-size: 20px;
    cursor: auto;
}
.ObjectsList table td.objStatus svg{
    margin-left: 5px;
}