.Container{
    display: flex;
    flex-flow: row;
}
.FieldsList {
    flex-grow: 1;
}
.FieldsList th {
    text-align: right;
}
.Controls {
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
}