.Layout {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}



.footer {
    display: flex;
    flex-flow: row;
    justify-content: center;
    padding: 20px 0;
    width: 100%;
    border-top: 1px solid #cfcfcf;
}